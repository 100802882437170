@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: 100;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;


  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
}

.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--colorGrey100);
}

.modalHeading {
  composes: h1 from global;
  margin-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
